.responsiveCol {
    display: inline-grid;
}

@media only screen and (max-width: 991px) {
    .responsiveCol {
        text-align: center;
    }
}

.responsiveColRight {
    text-align: end;
}

@media only screen and (max-width: 575px) {
    .responsiveColLeft {
        text-align: center;
    }

    .responsiveColRight {
        text-align: center;
    }
}
